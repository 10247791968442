import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import DesktopError from 'Containers/desktop/error';
import DesktopNotFound from 'Containers/desktop/notFound';
import MobileError from 'Containers/mobile/error';
import MobileNotFound from 'Containers/mobile/notFound';

import { sendException } from 'Utils/coralogix/log';
import { isProductionEnvironment } from 'Utils/envUtils';
import PlatformUtils from 'Utils/platformUtils';
import { getLocationObjectFromRouter } from 'Utils/urlUtils';

import { fetchCities } from 'Thunks/city';
import { changePage } from 'Actions/page';

import {
	LOG_CATEGORIES,
	LOG_LEVELS,
	PAGE_TYPE,
	SENTRY_TAGS,
	UNKNOWN_ERROR,
} from 'Constants/constants';

const ErrorPage = (props: any) => {
	const { statusCode, err } = props;
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(changePage(PAGE_TYPE.ERROR));
		if (statusCode !== 404) {
			sendException({
				exception: err?.stack ? err : new Error(UNKNOWN_ERROR),
				hint: {
					tags: {
						exceptionLabel: SENTRY_TAGS.ERROR_PAGE_VIEWED,
					},
				},
				title: 'ERROR_PAGE_VIEWED',
				severity: LOG_LEVELS.ERROR as any,
				methodName: 'ErrorPage',
			});
		}
	}, [err, statusCode]);

	const desktopError =
		statusCode === 404 ? (
			<DesktopNotFound {...props} />
		) : (
			<DesktopError {...props} />
		);
	const mobileError =
		statusCode === 404 ? (
			<MobileNotFound {...props} />
		) : (
			<MobileError {...props} />
		);
	return PlatformUtils.isDesktop() ? desktopError : mobileError;
};

ErrorPage.getInitialProps = async ({
	store,
	query,
	pathname,
	asPath,
	res,
	req,
	err,
}: any) => {
	if (req && res && isProductionEnvironment()) {
		if (res.statusCode !== 404) {
			sendException({
				exception: err?.stack ? err : new Error(UNKNOWN_ERROR),
				hint: {
					tags: {
						exceptionLabel: SENTRY_TAGS.ERROR_PAGE_VIEWED,
					},
				},
				title: `[PAGE LOADING ERROR] [${res.statusCode}] - ${asPath}`,
				severity: LOG_LEVELS.WARNING as any,
				methodName: 'ErrorPage.getInitialProps',
				category: LOG_CATEGORIES.PAGE_LOADING_ERROR,
			});
		}
	}
	const location = getLocationObjectFromRouter(query, pathname, asPath);
	await store.dispatch(changePage(PAGE_TYPE.ERROR));
	await store.dispatch(fetchCities());
	return { location, err };
};

export default ErrorPage;
